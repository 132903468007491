import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
  inject,
} from '@angular/core';
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialogConfig,
} from '@angular/material/dialog';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from '@angular/material/form-field';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { TitleStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalService,
} from '@azure/msal-angular';
import {
  provideCookieInterceptor,
  providePreviewBuildInterceptor,
  provideBypassServiceWorkerInterceptor,
  provideNotFoundInterceptor,
  providePublicLanguageInterceptor,
  provideApplicationConfig,
} from '@cca-infra/core';
import { provideAuthentication } from '@cca-common/authentication';
import {
  provideApplicationErrorHandler,
  provideBuildInfo,
} from '@cca-common/core';
import {
  WINDOW,
  providePlatformEnvironment,
  rootUrlToken,
} from '@cca-environment';
import { NavigationServiceToken } from '@cca-common/header';
import { PageTitleStrategy } from '@cca-common/page-title';
import {
  TranslocoHttpBackendToken,
  TranslocoRootModule,
} from '@cca-common/translations';
import { provideTranslocoScope } from '@jsverse/transloco';
import { environment } from '../environments/environment';
import { buildInformation } from '../version';
import { AppRoutingModule } from './app-routing.module';
import {
  MSALGuardConfigFactory,
  MSALInstanceFactory,
  MSALInterceptorConfigFactory,
} from './auth-config';
import {
  MAT_PAGINATOR_DEFAULT_OPTIONS,
  MatPaginatorDefaultOptions,
} from '@angular/material/paginator';
import { TranslationService } from '@cca-infra/translation-management/v1';
import { provideSequenceInterceptor } from '@cca-common/sequence/core';

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      // angular modules
      BrowserModule,
      // translations
      TranslocoRootModule,

      AppRoutingModule,

      MsalModule,
      // service worker
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: environment.serviceWorker,
      }),
    ),
    providePlatformEnvironment(environment),
    provideApplicationConfig(environment.api),
    provideApplicationErrorHandler(environment.features.logger),
    provideAuthentication(environment.features.authentication),
    provideSequenceInterceptor(),
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        floatLabel: 'always',
        appearance: 'outline',
      } as MatFormFieldDefaultOptions,
    },
    {
      provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
      useValue: {
        formFieldAppearance: 'outline',
      } as MatPaginatorDefaultOptions,
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        disableClose: true,
      } as MatDialogConfig,
    },

    // interceptors
    provideNotFoundInterceptor(),
    provideBypassServiceWorkerInterceptor(),
    provideCookieInterceptor(),
    providePreviewBuildInterceptor(),
    providePublicLanguageInterceptor(),

    // root scope
    provideTranslocoScope('web'),
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => null,
      deps: [NavigationServiceToken],
      multi: true,
    },
    provideBuildInfo(buildInformation),
    // provide PageTitleStrategy
    PageTitleStrategy,
    {
      provide: TitleStrategy,
      useExisting: PageTitleStrategy,
    },
    provideAnimationsAsync(),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    // provideClientHydration(withNoHttpTransferCache()),
    {
      provide: rootUrlToken,
      useFactory: () => {
        const window = inject(WINDOW);
        return `${window?.location?.origin}/`;
      },
    },

    // Single Sign on providers
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalBroadcastService,
    MsalGuard,
    // provide a backend service to get translations from
    {
      provide: TranslocoHttpBackendToken,
      useClass: TranslationService,
    },
  ],
};
