import {
  inject,
  Injectable,
  Injector,
  runInInjectionContext,
} from '@angular/core';
import { CanActivateFn } from '@angular/router';
import {
  AuthenticationConfigToken,
  AuthenticationFacade,
} from '@cca-common/authentication';
import { RouterFacade } from '@cca-common/cdk';
import { flatMenuItems, NavigationLinkType } from '../navigation';
import { ErrorLogService } from '@cca-infra/event-management/v1';
import { AuthViewModel, LandingPageType } from '@cca-infra/user-management/v1';
import { navigationPermissionCheck } from './permission-check';

export const indexGuardFn: CanActivateFn = () => {
  const guard = inject(IndexGuard);
  return guard.canActivate();
};

@Injectable({
  providedIn: 'root',
})
class IndexGuard {
  private authentication = inject(AuthenticationFacade);
  private injector = inject(Injector);
  private router = inject(RouterFacade);
  private errorService = inject(ErrorLogService);
  private authenticationConfig = inject(AuthenticationConfigToken);

  canActivate() {
    const user = this.authentication.user();
    if (!user) {
      this.authentication.logout();
      this.router.parseUrl(this.authenticationConfig.urlAfterLogout);
    }

    // try to make a navigation by landing page type, everyone should have one
    if (
      user?.landingPageType ||
      user?.landingPageType === LandingPageType.None
    ) {
      if (this.navigateByLandingPageType(user.landingPageType, user)) {
        return true;
      }
    }
    // report to logs if someone does not
    else {
      this.errorService
        .reportError({
          message: `User: ${user?.userDetail?.userName} [${user?.userId}] has no landingPageType`,
          stack: JSON.stringify(user),
        })
        .subscribe();
    }

    // if someone did not have a landing page or we could not match their landing page, use fallback
    return this.findPageWithPermissions();
  }

  navigateByLandingPageType(
    landingPageType: LandingPageType,
    user: AuthViewModel,
  ) {
    // map landing page type with a navigate command
    switch (landingPageType) {
      case LandingPageType.Dashboard:
        return this.tryNavigateToLandingPage('/dashboard', user);
      case LandingPageType.Home:
        return this.tryNavigateToLandingPage('/home', user);
      case LandingPageType.Booking:
        return this.tryNavigateToLandingPage('/booking', user);
      case LandingPageType.Order:
        return this.tryNavigateToLandingPage('/order', user);
      case LandingPageType.Request:
        return this.tryNavigateToLandingPage('/request', user);
      case LandingPageType.Fleet:
        return this.tryNavigateToLandingPage('/fleet', user);
      case LandingPageType.Lanes:
        return this.tryNavigateToLandingPage('/lane', user);
      case LandingPageType.Addressbook:
        return this.tryNavigateToLandingPage('/addressbook', user);
      case LandingPageType.User:
        return this.tryNavigateToLandingPage('/user', user);
      case LandingPageType.Group:
        return this.tryNavigateToLandingPage('/groups', user);
      case LandingPageType.Rates:
        return this.tryNavigateToLandingPage('/rates', user);
      case LandingPageType.Translation:
        return this.tryNavigateToLandingPage('/translation', user);
      case LandingPageType.Tender:
        return this.tryNavigateToLandingPage('/tender', user);
      case LandingPageType.Legal:
        return this.tryNavigateToLandingPage('/terms', user);

      // if nothing matches we return false
      default:
        return false;
    }
  }

  tryNavigateToLandingPage(link: string, user: AuthViewModel) {
    const navigationItem = flatMenuItems.find((nav) => nav.link === link);
    const success = this.navigateAndCheckPermission(navigationItem);
    if (!success) {
      this.errorService
        .reportError({
          message: `User: ${user.userDetail.userName} [${user.userId}] with LandingPage ${user.landingPageType} does not have permissions to access this landing page`,
          stack: JSON.stringify(user),
        })
        .subscribe();
    }
    return success;
  }

  // will traverse a list of menu items and attempt to navigate to first matching one
  findPageWithPermissions() {
    // try every page until one is successful
    for (const navigationItem of flatMenuItems) {
      const success = this.navigateAndCheckPermission(navigationItem);
      if (success) {
        return true;
      }
    }

    // we don't have access to any page
    return this.router.navigate(['./page-not-permitted']);
  }

  // tries to check the users permissions before navigating, if no permissions returns false. otherwise true
  navigateAndCheckPermission(navigation: NavigationLinkType | undefined) {
    if (!navigation || !navigation?.link) {
      return false;
    }

    const permissionCheckResult = runInInjectionContext(this.injector, () =>
      navigationPermissionCheck(navigation),
    );
    if (permissionCheckResult) {
      this.router.navigate([navigation.link], {
        replaceUrl: true,
      });
      return true;
    }

    return false;
  }
}
